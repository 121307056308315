export default [
    {
        headerIcon: 'cilStar',
        title: 'Reward Type',
        content: `When creating a reward in an Achievement or Contest,
                  there is a section where you need to enter the Reward types that are used to explain the type.
                  To start creating your own reward type navigate to the Reward Type page.`,
        to: 'settings/reward-types',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Tags',
        content: `Tagging models lets you easily group the models and later on search for specified
                  groups,
                  use the groups in rules, populate data depending on the groups.
                  To start creating your own tags navigate to the Tags page.`,
        to: 'settings/tags',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Action types',
        content: `An Action type lets you pre-create an action,
                  which can be used later to create rule or product definitions, etc., which enables you to use the
                  platform faster. If events are being sent to your space the action types are generated automatically.
                  To start creating your own action types navigate to the Action types page.`,
        to: 'settings/action-types',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Custom Field',
        content: `A Custom Field is used when you are creating an event and you need specific
              documentation about
              something that is not included in the standard creation tab.
              To start creating your own custom fields navigate to the Custom fields page.`,
        to: `settings/custom-fields`,
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Languages',
        content: `Languages is a translation where you manually add the languages that may be used in
              your event creation, and your labels and descriptions can be translated to a specific language. All
              languages are already in the Ziqni system,
              so you just need to select the ones you need.
              To add a language to your space navigate to the Languages page`,
        to: 'settings/languages',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Units of measure',
        content: `Units of measure is a type of unit that you create beforehand, that you can use
              later in creating an event from a list that you have made.
              To start creating your own unit of measure navigate to the Units of measure page.`,
        to: 'settings/units-of-measure',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Collaborators',
        content: `A collaborator in Ziqni is a user that can make changes, add events, etc.
              on your created account.Depending on their Role, a collaborator can have full or restricted access to the account.
              To add a collaborator to your space navigate to the Collaborators page.`,
        to: 'settings/collaborators',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'API Keys',
        content: `An Application Programming Interface key (API key) is a code passed in by computer
              programs to call an API to identify the calling program, its developer, or its user to the Web site. API
              keys are used to track and control how the API is being used.
              Api keys will be restricted depending on their roles.
              To generate your own api keys navigate to the Api keys page.`,
        to: 'settings/api-keys',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Transformers',
        content: `Ziqni Transformers are a recommended way to integrate with the
              Ziqni platform without
              having to make modifications to your message formats in your back-end systems.
              You can use Ziqni Transformers to send a message to Ziqni in your current format,
              transform the message into a form that can be accepted by the Ziqni API, and push that message
              to Ziqni.
              You can also transform messages before they are POSTed to your system via the Ziqni WebHooks.
              To start creating your own transformer navigate to the Transformers page.`,
        to: 'settings/transformers',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Webhooks',
        content: `Webhooks is a communication service
              which allows you to configure return call-back services depending on triggers.
              To create a Webhook you first need a Transformer for it to work.`,
        to: 'settings/webhooks',
        footerIcon: 'cilArrowRight',
    },
    {
        headerIcon: 'cilStar',
        title: 'Connections',
        content: `Event Connections configures the connection between an incoming event queue via Sqs,
              RabbitMQ or Kafka and a Transformer.
              To create an Event Connection you first need a Transformer for it to work.`,
        to: 'settings/connections',
        footerIcon: 'cilArrowRight',
    },
]