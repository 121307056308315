<template>
  <div>
    <h3 class="zq-page-title setting-title">Settings</h3>
    <div class="settings">
      <div class="settings--content content">
        <ClCommonCard
          v-for="(item, index) in cardsData"
          :key="index"
          :title="item.title"
          class="mb-3"
          :header-icon="item.headerIcon"
          :content="item.content"
          :to="item.to"
          :footer-icon="item.footerIcon"
        ></ClCommonCard>
      </div>
    </div>
  </div>
</template>

<script>
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ClCommonCard from '@/shared/UI/ClCommonCard';
//
import cardsData from './data';

export default {
  name: 'Settings',
  components: {
    ClCommonCard,
  },
  data() {
    return {
      cardsData: cardsData,
    };
  },
  created() {
    routerBreadcrumbs(this.$router.currentRoute);
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/settings' });
    },
  },
};
</script>
<style lang="scss">
.setting-title {
  background: #f7f7f7 !important;
}
.settings {
  height: 100%;
  background: #f7f7f7 !important;
  border: 0px !important;
  margin-top: -0.6rem;

  .settings--content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 1rem;
    border-top: 0px !important;
    background: #f7f7f7 !important;
  }

  .content {
    height: calc(100% - 50px);
  }

  .nav-settings {
    cursor: pointer;
    position: absolute;
    bottom: 1rem;
    right: 0;
  }
}
</style>
